import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SelectComponent } from '@sb/ui';
import omit from 'lodash-es/omit';
import pickBy from 'lodash-es/pickBy';
import { Subscription } from 'rxjs';

import {
  IntegrationEmployeeField,
  IntegrationEmployeeSync,
} from '../../../reducers/orm/integration/integration-configure.model';
import { getArrayDifference } from '../../array.helper';
import { TreeviewItem } from '../../ngx-treeview/treeview-item';

export const INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IntegrationEmployeeMultiSelectComponent),
  multi: true,
};

@Component({
  selector: 'integration-employee-multi-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './integration-employee-multi-select.component.html',
  providers: [INPUT_CONTROL_VALUE_ACCESSOR],
  standalone: true,
  imports: [SelectComponent, ReactiveFormsModule],
})
export class IntegrationEmployeeMultiSelectComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  @HostBinding('class.w-full')
  public fullWidth = true;

  @Input()
  public fieldsToOmit: IntegrationEmployeeField[] = [];

  public selectControl = new UntypedFormControl([]);
  public onTouched: Function;
  public onModelChange: Function;
  public items: TreeviewItem[] = [];
  private value: {};
  private dataSubs = new Subscription();

  public constructor(private translate: TranslateService) {}

  public ngOnInit() {
    for (const [key, val] of Object.entries(omit(IntegrationEmployeeSync, this.fieldsToOmit))) {
      this.items.push(
        new TreeviewItem({
          text: this.translate.instant(val as string),
          value: key,
        }),
      );
    }
  }

  public ngAfterViewInit() {
    this.dataSubs.add(
      this.selectControl.valueChanges.subscribe((val: string[]) => {
        if (!val) {
          return;
        }
        this.updateVal(val);
        this.onModelChange(this.value);
      }),
    );
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  // called by the reactive form control
  public registerOnChange(fn: Function) {
    // assigns to our internal model change method
    this.onModelChange = fn;
  }

  // called by the reactive form control
  public registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  public writeValue(value: {}) {
    if (!value) {
      return;
    }
    this.selectControl.setValue(Object.keys(pickBy(value)));
  }

  public updateVal(value: string[]) {
    const valueObj = {};
    value.forEach((val) => {
      valueObj[val] = true;
    });
    const falseValues = getArrayDifference(Object.keys(omit(IntegrationEmployeeSync, this.fieldsToOmit)), value);
    falseValues.forEach((val) => {
      valueObj[val] = false;
    });
    this.value = valueObj;
  }

  // called by the reactive form control
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectControl.disable();
    } else {
      this.selectControl.enable();
    }
  }
}
